import Swiper, { Autoplay, EffectFade } from 'swiper';

Swiper.use([Autoplay, EffectFade]);

let swiper = new Swiper('.js_top_banner_carousel', {
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  speed: 1000,
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
});

export {};
