import ScrollToVertical from 'scroll-to-vertical';

let body = document.body;
let mobileMenuTrigger = document.querySelectorAll('.js_mobile_menu_trigger');
let toTop = document.querySelector('.js_to_top');

mobileMenuTrigger.forEach((elm) => {
  elm.addEventListener('click', (event) => {
    body.classList.toggle('b_mob_nav_open');
  });
});

new ScrollToVertical('.js_scroll_menu_to_elem', {
  beforeAnimationCallback: function () {
    body.classList.remove('b_mob_nav_open');
  },
  typeToScrollAdd: 120,
  typeToScroll: 'data-href'
});

new ScrollToVertical('.js_to_top', {
  typeToScroll: 0
});

document.body.onscroll = function( event ) {
  let pageOffset = window.pageYOffset;
  const toggleOffset = 200;

  if (pageOffset > toggleOffset) {
    toTop.classList.add('b_top_page__show');
  } else {
    toTop.classList.remove('b_top_page__show');
  }
};

export {};
